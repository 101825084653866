/*===========================================================

   Template Name: Riji - Personal Portfolio React Template
   Author: Harnish Design
   Author URL: https://themeforest.net/user/harnishdesign
   File Description : Main css file of the template
	
=================================================
  Table of Contents
=================================================

	1. Basic
	2. Helpers Classes
	3. Layouts
	4. Header
		4.1 Navigation
		4.2 Secondary Nav
		4.3 Page Header
	5 Elements
		5.1 Featured Box
		5.2 Team
		5.3 Accordion & Toggle
		5.4 Nav
		5.5 Tabs
		5.6 Hero Background
		5.7 Slick Slider
		5.8 Brands Grid
		5.9 Portfolio
		5.10 List Style
	6 Footer
		6.1 Social Icons
		6.2 Back to Top
	7 Extra

=======================================================*/

//---------- Fonts ----------//

$body-font: "Quicksand", sans-serif;

.allura-regular {
  font-family: "Allura", cursive;
  font-weight: 400;
  font-style: normal;
}

//---------- Base Colors ----------//

$primary-color: $teal;

$primary-color-hover: darken($primary-color, 7%);
$secondary-color: $secondary;

$body-bg: #dddddd;
$text-color: #4c4d4d;
$title-color: #252b33;

//---------- Social Icons Colors ----------//

$facebook: #3b5998;
$twitter: #e7d4c1;
$google: #dd4b39;
$linkedin: #e7d4c1;
$youtube: #c4302b;
$instagram: #e7d4c1;
$dribbble: #ea4c89;
$github: #e7d4c1;
$behance: #053eff;
$reddit: #c6c6c6;
$pinterest: #cc2127;
$vk: #2b587a;
$rss: #ee802f;
$skype: #00aff0;
$xing: #126567;
$tumblr: #34526f;
$email: #6567a5;
$delicious: #205cc0;
$stumbleupon: #f74425;
$digg: #191919;
$blogger: #fc4f08;
$flickr: #ff0084;
$vimeo: #86c9ef;
$yahoo: #720e9e;
$appstore: #000;
