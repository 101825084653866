.profile-picture img {
  width: 290px; /* Adjust the size as needed */
  height: 290px; /* Adjust the size as needed */
  border-radius: 50%; /* This makes the image round */
  border: 9px solid #ad746f; /* Adjust color and size of border as needed */
  object-fit: cover; /* This will cover the area without stretching the image */
  margin: auto;
  display: block;
  margin-top: -60px;
}

body {
  overflow-x: hidden;
}
/* Styles the scrollbar track with semi-transparent background */

.btn.btn-outline-primary.rounded-pill.shadow-none.smooth-scroll.mt-2:hover {
  color: white !important;
} /* Increasing specificity */
body .brands-grid.separator-border > .row > *:before {
  content: none;
}

/* Using !important */
.brands-grid.separator-border > .row > *:before {
  content: none !important;
}

@media only screen and (max-width: 480px) {
  .profile-picture img {
    width: 300px; /* Smaller size for mobile screens */
    height: 300px; /* Smaller size for mobile screens */
    border: 8px solid #ad746f; /* Thinner border */
    margin-top: 0px; /* Adjust the top margin */
  }
}

@media only screen and (min-width: 481px) and (max-width: 1024px) {
  .profile-picture img {
    width: 300px; /* Medium size for tablet screens */
    height: 300px; /* Medium size for tablet screens */
    border: 6px solid #ad746f; /* Adjust border size */
    margin-top: -50px; /* Adjust the top margin */
  }
}

// Base style for the icons
.social-icon {
  width: 60px; // Default size
  height: 60px;
  display: block;
  margin: auto;

  // Mobile devices
  @media only screen and (max-width: 399px) {
    width: 40px; // Smaller icons for mobile
    height: 40px;
  }

  // Mobile devices
  @media only screen and (min-width: 400px) and (max-width: 480px) {
    width: 50px; // Smaller icons for mobile
    height: 50px;
  }

  // Tablet devices
  @media only screen and (min-width: 481px) and (max-width: 1024px) {
    width: 55px; // Medium icons for tablets
    height: 55px;
  }

  // Laptop devices
  @media only screen and (min-width: 1025px) and (max-width: 1920px) {
    width: 70px; // Slightly larger icons for laptops
    height: 70px;
  }

  // High-resolution displays
  @media only screen and (min-width: 1921px) and (max-width: 2560px) {
    width: 60px; // Larger icons for high-resolution displays
    height: 60px;
  }
}

// Applying the style to each icon by adding the 'social-icon' class to the 'img' tag
.featured-box img {
  @extend .social-icon;
}

@media only screen and (max-width: 480px) {
  .featured-box p {
    display: none; // Hide the text for small screens
  }
}

// Base style for text under icons
.social-text {
  font-size: 16px; // Default font size

  // @media only screen and (max-width: 375px) {
  //   .featured-box p {
  //     display: none; // This hides the text
  //   }

  //   .featured-box {
  //     width: auto; // Resets any specific width, allowing the icon to center itself
  //     h4 {
  //       margin: 0; // Ensures no additional space is taken by h4 which wraps the image
  //     }
  //   }
  // }

  // Mobile devices
  @media only screen and (max-width: 480px) {
    font-size: 10px; // Smaller font size for mobile
  }

  // Tablet devices
  @media only screen and (min-width: 481px) and (max-width: 1024px) {
    font-size: 12px; // Medium font size for tablets
  }

  // Laptop devices
  @media only screen and (min-width: 1025px) and (max-width: 1920px) {
    font-size: 14px; // Slightly larger font size for laptops
  }

  // High-resolution displays
  @media only screen and (min-width: 2560px) {
    font-size: 16px; // Default font size for high-resolution displays
  }
}

// Applying the style to each text element by adding the 'social-text' class
.featured-box p {
  @extend .social-text;
}
