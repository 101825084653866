::-webkit-scrollbar {
  width: 10px; /* Adjust the width of the scrollbar */
  background-color: #2e3014; /* Semi-transparent track */
}

/* Styles the scrollbar handle */
::-webkit-scrollbar-thumb {
  background-color: #ad746f; /* Black handle */
  border-radius: 10px; /* Rounded corners for the handle */
}

/* Styles for the scrollbar handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #7d4f4e; /* Darker handle on hover */
}

/* Desktop and larger screens */
.hero-bg {
  background-image: url("../../public/images/placeholder.png") !important;
  background-size: cover !important;
  background-position: center center !important;
}

/* Mobile screens */
@media only screen and (max-width: 768px) {
  .hero-bg {
    background-image: url("../../public/images/placeholder.png") !important; /* Optional: use a different image for mobile */
    background-size: cover !important; /* Or 'cover', depending on the desired effect */
    background-repeat: no-repeat !important;
    background-attachment: scroll !important;
  }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
  .hero-bg {
    background-image: url("../../public/images/placeholder.png") !important; /* Optional: use a different image for mobile */
    background-size: cover !important; /* Or 'cover', depending on the desired effect */
    background-repeat: no-repeat !important;
    background-attachment: scroll !important;
  }

  /* Styles for iPad Pro */
}
