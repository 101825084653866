.title {
  text-align: center;
  font-family: "Quicksand", sans-serif;
}

h3 {
  padding-top: 0.25em;
}

.my-timeline-container {
  --line-color: #7d4f4e; /* Replace with your desired color */
}
.timeline-date {
  display: flex;
  padding-bottom: 5px;
  justify-content: right;
}
.vertical-timeline .vertical-timeline-element-icon {
  width: 20px !important;
  height: 20px !important;
  transform: translate(-40%, 140%);
  background: #7d4f4e; /* Replace with the color you want */
  // border: 2px solid ; /* Replace with the border color you want */
  box-shadow: none; /* Optional: remove if you don't want any shadow */
}

.vertical-timeline-element-content {
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.15),
    0 0.4em 1.25em 0 rgba(0, 0, 0, 0.1) !important;
  padding: 2em !important;
  border-radius: 25px !important;
  width: 100% !important;
  // transform: translate(0%, 0%);
  font-size: 10pt;
}

/* Force all timeline elements to the right side */
.my-timeline-container .vertical-timeline-element-content {
  margin-left: 2.5em !important;
  margin-right: 0 !important;
  /* Adjust the alignment as necessary */
}

.vertical-timeline::before {
  left: 0px !important; /* Move line to the very left of the container */
  background: linear-gradient(to right, transparent, #7d4f4e, transparent);
  border-radius: 2px; /* half of the height to make ends fully rounded */
}

.vertical-timeline-element-icon {
  left: 0px !important; /* Adjust as necessary to align with the line */
  transition: none !important;
  animation: none !important;
}

.vertical-timeline-element-content-arrow {
  display: none;
}

.vertical-timeline-element-subtitle {
  padding-top: 2%;
  font-size: 14pt;
}

.spacer {
  display: flex;
  justify-content: space-between;
}

.vertical-timeline-element-title {
  font-size: 15pt;
}

.section-resume {
  margin-left: 200px;
  margin-right: 200px;
}

.timelineEnd {
  margin-bottom: 40px;
}

.pdfButton {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1em;
}

.skills-certifications {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  margin: 1em;

  @media only screen and (max-width: 768px) {
    flex-direction: row; // Change layout to row for screens 768px and smaller
    flex-wrap: wrap; // Allow items to wrap to the next line
    justify-content: center; // Center items in the row
    gap: 10px; // Add a gap between items for spacing

    .certificate-card,
    .skill {
      font-size: small;
      flex: 1 1 auto; // Allow items to grow and shrink as needed
      max-width: calc(100% - 10px); // Three items per row, minus the gap
    }
  }
}
.certificates-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around; /* Adjust the spacing between cards */
  gap: 20px; /* Space between the cards */
  margin: 1.5em;
}

.certificate-card {
  display: flex;
  width: 18%; /* Adjust card width as necessary */
  text-decoration: none; /* Removes the underline from links */
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 0.4em;

  color: #000; /* Text color, adjust as needed */
  background-color: white; /* Your desired background color */
  // outline-color: #ad746f;
  border-radius: 10px; /* Optional rounded corners */
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.25); /* Optional shadow for depth */
  transition: transform 0.3s ease; /* Smooth transition for hover effect */
}

.certificate-card:hover {
  transform: translateY(-5px); /* Slight lift effect on hover */
}

.card-content {
  padding: 15px; /* Padding inside the cards */
}

.card-content h4 {
  margin: 0;
  font-size: 15pt;
  padding-bottom: 10px; /* Space between the title and description */
  font-weight: bold;
}

@media (max-width: 768px) {
  .certificates-container {
    flex-direction: column;
    align-items: center;
  }

  .certificate-card {
    width: 80%; /* Larger width for smaller screens */
  }
}
.skill-cloud {
  border-radius: 15px; // Applies rounded corners
  display: flex; // Uses flexbox to layout skill bubbles
  flex-wrap: wrap; // Allows skill bubbles to wrap to the next line
  justify-content: center; // Centers skill bubbles horizontally
  gap: 10px; // Adds gaps between skill bubbles
  margin: 20px 0; // Adds some margin above and below the container
  max-width: 800px; // Maximum width of the container
  width: 40%; // Makes the container width responsive
  align-items: center; // Centers the skill bubbles vertically
}

.skill {
  padding: 5px 10px;
  border-radius: 15px;
  background-color: #7d4f4e;
  transition: transform 0.3s ease;
  color: white;
}

.skill {
  transition: transform 0.5s ease;
  will-change: transform;
}

@media only screen and (max-width: 480px) {
  .section-resume {
    margin-left: 20px;
    margin-right: 20px;
  }

  .vertical-timeline-element-content {
    padding: 1em !important; /* Less padding for smaller screens */
    border-radius: 15px !important;
    width: 90% !important; /* Adjust width for smaller screens */
  }

  .certificate-card {
    width: 90%; /* Adjust for smaller screens */
  }

  .skill-cloud {
    width: 90%; /* Adjust container width for smaller screens */
    margin: 10px 0; /* Adjust margin */
  }
}

@media only screen and (min-width: 481px) and (max-width: 1024px) {
  .section-resume {
    margin-left: 45px;
    margin-right: 45px;
  }

  .certificate-card {
    width: 45%; /* Adjust cards to fit two per row */
  }

  .skill-cloud {
    width: 80%; /* Adjust for tablet screens */
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1920px) {
  .section-resume {
    margin-left: 150px;
    margin-right: 150px;
  }

  .certificate-card {
    width: 30%; /* Adjust for medium-sized screens */
  }

  .skill-cloud {
    width: 60%; /* Adjust for wider screens */
  }
}

// @media only screen and (min-width: 2560px) {
//   .section-resume {
//     margin-left: 200px;
//     margin-right: 200px;
//   }

//   .vertical-timeline-element-content {
//     padding: 2.5em !important; /* More padding for larger screens */
//     border-radius: 30px !important;
//     width: 80% !important; /* Adjust width for larger screens */
//   }

//   .certificate-card {
//     width: 20%; /* More cards per row for larger screens */
//   }

//   .skill-cloud {
//     width: 50%; /* Adjust for very wide screens */
//     gap: 15px; /* Larger gap between skills for better visibility */
//   }
// }
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .vertical-timeline .vertical-timeline-element-icon {
    transform: translate(-40%, 0%); /* Adjust for tablet screens */
    width: 18px; /* Adjust icon size */
    height: 18px; /* Adjust icon size */
  }

  .my-timeline-container {
    /* You can adjust the width or padding as needed */
    padding: 0em; /* This adds horizontal padding on both sides */
  }

  .vertical-timeline-element-content {
    font-size: 0px; /* Slightly larger font for tablet readability */
    padding: 3em !important; /* Adjust padding */
    width: 100%;
    // animation: none !important;
    transform: translate(10%, 0%) !important;
    max-width: none;
  }
  .vertical-timeline-element-content #description {
    font-size: 14px; // Adjust the font size of the description for iPads
  }

  .vertical-timeline-element-title {
    font-size: 17px;
  }
  .vertical-timeline-element-subtitle {
    font-size: 15px; /* Adjust title and subtitle size */
  }
}

/* iPad in portrait and landscape orientation */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .vertical-timeline .vertical-timeline-element-icon {
    transform: translate(-40%, 175%); /* Adjust for tablet screens */
    width: 18px; /* Adjust icon size */
    height: 18px; /* Adjust icon size */
  }
  .vertical-timeline-element-content {
    padding: 20px; /* Provides padding inside the content box */
    width: auto; /* Resets any specific width, allowing content to fit the text */
    max-width: 100%; /* Allows content box to expand to the full width of its container */
    box-sizing: border-box; /* Makes sure padding doesn't affect the overall width */

    /* If the description has its own class or ID, you might want to adjust it separately */
    #description,
    .description {
      /* Replace with the actual selector used for your descriptions */
      font-size: 14px; /* Adjust the font size if necessary */
    }

    /* Adjust the date and icon positioning */
    .timeline-date,
    .vertical-timeline-element-icon {
      font-size: 14px; /* Adjust the date font size if necessary */
      /* Additional styling to position the date and icon */
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 3000px) {
  .vertical-timeline .vertical-timeline-element-icon {
    transform: translate(
      -42%,
      100%
    ); /* Re-adjust icon position for larger screens */
    width: 20px; /* Revert to original icon size */
    height: 20px; /* Revert to original icon size */
  }
}

// @media only screen and (min-width: 1921px) and (max-width: 2056px) {
//   .vertical-timeline::before {
//     left: 0px !important; /* Move line to the very left of the container */
//     background: linear-gradient(to right, transparent, #7d4f4e, transparent);
//     border-radius: 2px; /* half of the height to make ends fully rounded */
//     transform: translate(30%);
//   }
//   .vertical-timeline-element-content {
//     padding: 20px; /* Provides padding inside the content box */
//     width: auto; /* Resets any specific width, allowing content to fit the text */
//     max-width: 100%; /* Allows content box to expand to the full width of its container */
//     box-sizing: border-box; /* Makes sure padding doesn't affect the overall width */
//     transform: translate(-50%, 0%);

//     /* If the description has its own class or ID, you might want to adjust it separately */
//     #description,
//     .description {
//       /* Replace with the actual selector used for your descriptions */
//       font-size: 14px; /* Adjust the font size if necessary */
//     }

//     /* Adjust the date and icon positioning */
//     .timeline-date,
//     .vertical-timeline-element-icon {
//       font-size: 14px; /* Adjust the date font size if necessary */
//       /* Additional styling to position the date and icon */
//     }
//   }
// }
