.photography-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
}

.photography-item img {
  width: 100%;
  display: block;
  border-radius: 5px;
}

.pdf-carousel-image img {
  width: 200%; /* or any specific size */
  height: auto; /* maintain aspect ratio */
  max-width: 1200px; /* or any maximum size you prefer */
  display: block; /* to remove any extra space below the image */
  margin: auto; /* to center the image in the slide if it's smaller than the container */
}
.carousel img {
  width: 200%; /* or any specific width */
  height: auto; /* maintain aspect ratio */
  max-width: 1250px; /* or your desired maximum width */
}

.pdfButton {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1em;
  margin-bottom: 1em;

  a.btn {
    font-size: smaller; // Makes the font size smaller
    padding: 0.5em 1.5em; // Reduces the padding to make the button smaller overall
  }
}

/* Arrow button backgrounds */
.slick-prev:before,
.slick-next:before {
  color: #ad746f; /* Your desired color */
}

/* Hover state if needed */
.slick-prev:hover:before,
.slick-next:hover:before {
  color: #7d4f4e; /* Color on hover */
}
// /* Adjustments for screens smaller than 1400px */
@media only screen and (max-width: 1199px) {
  .carousel img {
    width: 100%; /* Adjust to 100% of the container width */
    max-width: 1000px; /* Adjust max-width as needed */
    height: auto; /* Maintain aspect ratio */
  }
}

// ... existing styles

// Media query for screens smaller than 575px
@media only screen and (max-width: 575px) {
  .slick-prev,
  .slick-next {
    display: none !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .carousel img {
    width: 100%; /* Adjust to 100% of the container width */
    max-width: 1300px; /* Adjust max-width as needed */
    height: auto; /* Maintain aspect ratio */
  }
}
