// Base style for the logo
.logo-image {
  width: 20%; // Fixed width for large screens
  height: auto; // Maintain aspect ratio
  max-width: 100%; // Ensure the image is not larger than its container

  // Mobile devices
  @media only screen and (max-width: 480px) {
    width: 50px; // Smaller logo for mobile
  }

  // Tablet devices
  @media only screen and (min-width: 481px) and (max-width: 768px) {
    width: 50px; // Medium logo for tablets
  }

  // Small laptop devices
  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    width: 60px; // Slightly larger logo for small laptops
  }

  // Large laptop and desktop devices
  @media only screen and (min-width: 1025px) {
    width: 60px; // Largest logo for large laptops and desktops
  }
}

// Styles for the navbar background
.bg-transparent {
  background-color: rgba(255, 255, 255, 0.5);
}

.bg-solid {
  background-color: #ffffff;
}

.nav-menu {
  background-color: white; /* or any other color */
  /* other styles */
}
